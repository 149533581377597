import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import loading from 'images/icons/three-dots.svg'

class SearchSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      setRadio: true,
      activeType: props.query ? props.query[0] : [],
      byParent: props.query ? props.query[1] : {},
      disabled: props.query ? !(props.query[0].length + Object.keys(props.query[1]).length === props.categories.length) : true
    }
  }

  // componentDidMount() {
  //   console.log('componentDidMount')
  // }

  filterTypes = (c) => {
    // if (this.state.activeType.length >= 3 && t.allways) {
    //   return true
    // }
    // console.log('c', c.id, t)

    // if (c === 3) {
    //   return false
    // }

    return t => {
      // if (c.id === 4 && this.state.activeType.length < 3) {
      //   return false
      // }

      // if (c.id === 5 && Object.keys(this.state.byParent).length >= 1) {
      //   return true
      // }

      return this.state.activeType.indexOf(t.parent) !== -1 || t.parent === null
    }
  }

  isChecked = (t, c) => {
    // if (c.id === 5 && Object.keys(this.state.byParent).length === 1) {
    //   document.getElementsByName('c5').forEach(e => e.checked = false)
    //   return false
    // }

    return this.state.setRadio && this.props.query && (this.props.query[0].indexOf(t.id) !== -1 || this.props.query[1][c.id] === t.id)
  }

  handleChange = (c) => (event) => {
    const t = event.target.value
    // let activeType = t.allways ? this.state.activeType : t.ancestors
    // let activeType = c.id >= 4 ? this.state.activeType : t.ancestors
    let activeType = this.state.activeType
    let byParent = this.state.byParent

    // if (c.id === 4) {
    //   byParent = {}
    //   byParent[c.id] = t.id
    // } else if (c.id === 5) {
    //   byParent[c.id] = t.id
    // } else {
    //   byParent = {}
    // }

    byParent[c.id] = t

    this.setState({
      byParent,
      activeType,
      setRadio: false,
      disabled: activeType.length + Object.keys(byParent).length === this.props.categories.length ? false : true
    })
  }

  render() {
    return (
      <div className="row search">
        {/*<h2>Wyszukiwarka</h2>*/}
        {this.props.categories.map(c =>
          <div key={c.id} className="col-md-3">
            <div className={`group ${!this.state.byParent.hasOwnProperty(c.id) ? 'empty' : 'filled-single'}`}>
              <h4>{this.state.byParent.hasOwnProperty(c.id) ? c.types.find(t => t.id == this.state.byParent[c.id]).name : c.name}</h4>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.36 12.01">
                <polyline points="16.36 10.01 10.59 4.24 9.18 2.83 7.76 4.24 2 10.01" />
              </svg>
              {<select onChange={this.handleChange(c)} name={`c${c.id}`} value={this.state.byParent[c.id]}>
                <option value="">{c.name}</option>
                {c.types.map(t =>
                  <option key={t.id} value={t.id}>{t.name}</option>
                )}
                {/* {c.types.filter(this.filterTypes(c)).length === 0 &&
                  <li className="loading"><i className ="fa fa-ellipsis-h"></i></li>
                } */}
              </select> }
            </div>
          </div>
        )}

        <div className="col-md-3 actions">
          <form method="GET" action={this.props.url}>
            <input type="hidden" name="q" value={JSON.stringify([this.state.activeType, this.state.byParent])} />
            <button className="btn btn-primary">{this.props.btn}</button>
          </form>
        </div>
      </div>
    )
  }
}

SearchSelect.defaultProps = {
  categories: []
}

SearchSelect.propTypes = {
  categories: PropTypes.array
}

window.renderSearchSelect = (el) => {
  const url = el.getAttribute('data-url')
  const btn = el.getAttribute('data-btn')
  const categories = JSON.parse(el.getAttribute('data-categories'))
  const query = JSON.parse(el.getAttribute('data-query'))

  ReactDOM.render(
    <SearchSelect url={url} btn={btn} categories={categories} query={query} />,
    el
  )
}

document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById('search-select')

  if (el) {
    window.renderSearchSelect(el)
  }
})
